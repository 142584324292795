var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
        [
          _c("div", { staticClass: "card cardcontents" }, [
            _c("div", { staticClass: "card-header" }, [
              _vm._v(" (금일) 진행중인 업무 "),
              _c(
                "div",
                { staticClass: "card-more main-header-input" },
                [
                  _c("c-plant", {
                    attrs: {
                      type: "search",
                      name: "plantCd1",
                      isFirstValue: false,
                      label: "",
                    },
                    on: { datachange: _vm.getWorkProgress },
                    model: {
                      value: _vm.plantCd1,
                      callback: function ($$v) {
                        _vm.plantCd1 = $$v
                      },
                      expression: "plantCd1",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "card-body", staticStyle: { height: "213px" } },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.workProgressList, function (progress, idx) {
                    return _c(
                      "div",
                      {
                        key: idx,
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c(
                          "q-btn",
                          {
                            staticClass: "btn-fixed-width-height",
                            attrs: {
                              dense: "",
                              icon: _vm.iconItems[idx],
                              align: "left",
                              color: _vm.colorItems[idx],
                              outline: "",
                              unelevated: "",
                              label: progress.workName,
                            },
                          },
                          [
                            _c(
                              "q-badge",
                              {
                                attrs: {
                                  color: "red",
                                  floating: "",
                                  transparent: "",
                                },
                              },
                              [_vm._v(" " + _vm._s(progress.workCount) + " ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
        [
          _c("div", { staticClass: "card cardcontents" }, [
            _c("div", { staticClass: "card-header" }, [
              _vm._v(" (금일) 작업허가 현황 "),
              _c(
                "div",
                { staticClass: "card-more main-header-input" },
                [
                  _c("c-plant", {
                    attrs: {
                      type: "search",
                      name: "plantCd2",
                      isFirstValue: false,
                      label: "",
                    },
                    on: { datachange: _vm.getWorkPermits },
                    model: {
                      value: _vm.plantCd2,
                      callback: function ($$v) {
                        _vm.plantCd2 = $$v
                      },
                      expression: "plantCd2",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "card-body nopadgrid" },
              [
                _c("c-table", {
                  ref: "grid1",
                  attrs: {
                    tableId: "grid1",
                    isTitle: false,
                    isDashboard: true,
                    columnSetting: false,
                    usePaging: false,
                    filtering: false,
                    isFullScreen: false,
                    hideBottom: true,
                    gridHeight: "192px",
                    columns: _vm.grid1.columns,
                    data: _vm.grid1.data,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
        [
          _c("div", { staticClass: "card cardcontents" }, [
            _c("div", { staticClass: "card-header" }, [
              _vm._v(" 개선 진행 목록 "),
              _c(
                "div",
                { staticClass: "card-more main-header-input" },
                [
                  _c("c-plant", {
                    attrs: {
                      type: "search",
                      name: "plantCd3",
                      isFirstValue: false,
                      label: "",
                    },
                    on: { datachange: _vm.getImprProgress },
                    model: {
                      value: _vm.plantCd3,
                      callback: function ($$v) {
                        _vm.plantCd3 = $$v
                      },
                      expression: "plantCd3",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "card-body nopadgrid" },
              [
                _c("c-table", {
                  ref: "grid2",
                  attrs: {
                    tableId: "grid2",
                    isTitle: false,
                    isDashboard: true,
                    columnSetting: false,
                    usePaging: false,
                    filtering: false,
                    isFullScreen: false,
                    hideBottom: true,
                    gridHeight: "217px",
                    columns: _vm.grid2.columns,
                    data: _vm.grid2.data,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
        [
          _c("div", { staticClass: "card cardcontents" }, [
            _c("div", { staticClass: "card-header" }, [
              _vm._v(" (최근5년) 사고현황 "),
            ]),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("apexchart", {
                  ref: "yearOcuurChart",
                  attrs: {
                    height: "202px",
                    type: "line",
                    options: _vm.yearOcuurChart.chartOptions,
                    series: _vm.yearOcuurChart.series,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
        [
          _c("div", { staticClass: "card cardcontents" }, [
            _c("div", { staticClass: "card-header" }, [
              _vm._v(" 순회점검 개선 이행율 "),
            ]),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("apexchart", {
                  ref: "imprChart2",
                  attrs: {
                    height: "300px",
                    type: "bar",
                    options: _vm.imprChart2.chartOptions,
                    series: _vm.imprChart2.series,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9" },
        [
          _c("div", { staticClass: "card cardcontents" }, [
            _c("div", { staticClass: "card-header" }, [
              _vm._v(" 통합개선 이행율 현황 "),
              _c(
                "div",
                { staticClass: "card-more main-header-input" },
                [
                  _c("c-plant", {
                    attrs: {
                      type: "search",
                      label: "",
                      isFirstValue: false,
                      name: "plantCd4",
                    },
                    on: { datachange: _vm.datachange4 },
                    model: {
                      value: _vm.plantCd4,
                      callback: function ($$v) {
                        _vm.plantCd4 = $$v
                      },
                      expression: "plantCd4",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "card-more main-header-input main-header-input2",
                },
                [
                  _c("c-datepicker", {
                    attrs: {
                      range: true,
                      defaultStart: "-6M",
                      defaultEnd: "0M",
                      type: "month",
                      name: "actionCompleteRequestDates",
                    },
                    on: { datachange: _vm.getImprStatus },
                    model: {
                      value: _vm.actionCompleteRequestDates,
                      callback: function ($$v) {
                        _vm.actionCompleteRequestDates = $$v
                      },
                      expression: "actionCompleteRequestDates",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("apexchart", {
                  ref: "imprChart",
                  attrs: {
                    height: "300px",
                    type: "bar",
                    width: _vm.imprChart.chartWidth,
                    options: _vm.imprChart.chartOptions,
                    series: _vm.imprChart.series,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }