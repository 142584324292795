<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="card cardcontents">
          <div class="card-header">
            (금일) 진행중인 업무
            <div class="card-more main-header-input">
              <c-plant
                type="search"
                name="plantCd1"
                :isFirstValue="false"
                label=""
                v-model="plantCd1"
                @datachange="getWorkProgress" />
            </div>
          </div>
          <div class="card-body" style="height: 213px">
            <div class="row">
              <div v-for="(progress, idx) in workProgressList" 
                :key="idx"
                class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <q-btn dense :icon="iconItems[idx]" align="left" :color="colorItems[idx]" outline  unelevated :label="progress.workName" class="btn-fixed-width-height">
                  <q-badge color="red" floating transparent>
                    {{ progress.workCount }}
                  </q-badge>
                </q-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="card cardcontents">
          <div class="card-header">
            (금일) 작업허가 현황
            <div class="card-more main-header-input">
              <c-plant
                type="search"
                name="plantCd2"
                :isFirstValue="false"
                label=""
                v-model="plantCd2"
                @datachange="getWorkPermits" />
            </div>
          </div>
          <div class="card-body nopadgrid">
            <c-table
              ref="grid1"
              tableId="grid1"
              :isTitle="false"
              :isDashboard="true"
              :columnSetting="false"
              :usePaging="false"
              :filtering="false"
              :isFullScreen="false"
              :hideBottom="true"
              gridHeight="192px"
              :columns="grid1.columns"
              :data="grid1.data"
            >
            </c-table>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="card cardcontents">
          <div class="card-header">
            개선 진행 목록
            <div class="card-more main-header-input">
              <c-plant
                type="search"
                name="plantCd3"
                :isFirstValue="false"
                label=""
                v-model="plantCd3"
                @datachange="getImprProgress"/>
            </div>
          </div>
          <div class="card-body nopadgrid">
            <c-table
              ref="grid2"
              tableId="grid2"
              :isTitle="false"
              :isDashboard="true"
              :columnSetting="false"
              :usePaging="false"
              :filtering="false"
              :isFullScreen="false"
              :hideBottom="true"
              gridHeight="217px"
              :columns="grid2.columns"
              :data="grid2.data"
            >
            </c-table>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="card cardcontents">
          <div class="card-header">
            (최근5년) 사고현황
          </div>
          <div class="card-body">
            <apexchart 
              ref="yearOcuurChart" 
              height="202px" 
              type="line"
              :options="yearOcuurChart.chartOptions" 
              :series="yearOcuurChart.series"></apexchart>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <div class="card cardcontents">
          <div class="card-header">
            순회점검 개선 이행율
          </div>
          <div class="card-body">
            <apexchart 
              ref="imprChart2" 
              height="300px" 
              type="bar"
              :options="imprChart2.chartOptions" 
              :series="imprChart2.series"></apexchart>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <div class="card cardcontents">
          <div class="card-header">
            통합개선 이행율 현황
            <div class="card-more main-header-input">
              <c-plant
                type="search"
                label=""
                :isFirstValue="false"
                name="plantCd4"
                v-model="plantCd4"
                @datachange="datachange4" />
            </div>
            <div class="card-more main-header-input main-header-input2">
              <c-datepicker
                :range="true"
                defaultStart="-6M"
                defaultEnd="0M"
                type="month"
                name="actionCompleteRequestDates"
                v-model="actionCompleteRequestDates"
                @datachange="getImprStatus"
              />
            </div>
          </div>
          <div class="card-body">
            <apexchart 
              ref="imprChart" 
              height="300px" 
              type="bar"
              :width="imprChart.chartWidth"
              :options="imprChart.chartOptions" 
              :series="imprChart.series"></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import selectConfig from '@/js/selectConfig';
export default {
  name: 'main-dashboard-director',
  components: { 
    apexchart: VueApexCharts
  },
  props: {
  },
  data() {
    return {
      plantCd1: null,
      plantCd2: null,
      plantCd3: null,
      plantCd4: null,
      yearOcuurChart: {
        chartOptions: {
          chart: {
            type: 'line',
            zoom: {
              enabled: false
            },
          },
          title: {
            text: '',
            align: 'left',
            style: {
              fontSize: "14px",
              color: '#1ab394'
            }
          },
          dataLabels: {
            enabled: false
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            width: [3,3,3,3,3,3,3,3],
            curve: 'straight',
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 건"
              }
            }
          }
        },
        series: [],
        chartWidth: '100%',
      },
      imprChart: {
        chartOptions: {
          annotations: { 
            xaxis: [],
          },
          title: {
            // text: '업무별 개선 현황'
          },
          chart: {
            type: 'bar',
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          dataLabels: {
            enabled: true,
          },
          stroke: {
            show: true,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          colors: [
            '#FFC107', '#00BCD4', '#F44336',
          ],
          fill: {
            opacity: 1,
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 10
          },
        },
        series: [
          {
            id: 'actioning',
            name: '조치중',
            data: [],
          },
          {
            id: 'complete',
            name: '개선완료(총합)',
            data: [],
          },
          {
            id: 'overAction',
            name: '지연 건수',
            data: [],
          },
        ],
        chartWidth: '100%',
      },
      imprChart2: {
        chartOptions: {
          annotations: { 
            xaxis: [],
          },
          title: {
            text: ''
            // text: '업무별 개선 현황'
          },
          chart: {
            type: 'bar',
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          dataLabels: {
            enabled: true,
          },
          stroke: {
            show: true,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          colors: [
            '#FFC107', '#00BCD4', '#F44336',
          ],
          fill: {
            opacity: 1,
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 10
          },
        },
        series: [
          {
            id: 'actioning',
            name: '조치중',
            data: [],
          },
          {
            id: 'complete',
            name: '완료',
            data: [],
          },
          {
            id: 'overAction',
            name: '지연',
            data: [],
          },
        ],
        chartWidth: '100%',
      },
      rate: [],
      safRate: [],
      envRate: [],
      yearUrl: '',
      accident: {
        startYmd: '',
        endYmd: '',
      },
      grid1: {
        listUrl: '',
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'workSummary',
            field: 'workSummary',
            label: '작업개요',
            align: 'left',
            sortable: false,
          },
          {
            name: 'swpStepName',
            field: 'swpStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            sortable: false,
          },
          {
            name: 'permitDate',
            field: 'permitDate',
            label: '허가일',
            align: 'center',
            sortable: false,
          },
          {
            name: 'permitTypeName',
            field: 'permitTypeName',
            label: '허가서구분',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      grid2: {
        listUrl: '',
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            label: 'LBLWORK',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            sortable: false,
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            label: '조치완료 요청일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
      },
      rateUrl: '',
      patrolUrl: '',
      workPermitUrl: '',
      imprProgressUrl: '',
      workProgressUrl: '',
      actionCompleteRequestDates: [],
      workProgressList: [],
      showTooltopCnt: 0,
      showTooltop: [false,false,false,false,false,false,false,false],
      iconItems: ['school','settings_applications','build','build_circle','restore','manage_accounts','report','report_problem'],
      colorItems: ['primary','secondary','amber-9','deep-orange','secondary','brown-5','purple','green-7',],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.showTooltopCnt = 0
      this.yearUrl = selectConfig.sop.iim.accident.status.year.url;
      this.rateUrl = selectConfig.main.imprChart.url;
      this.patrolUrl = selectConfig.main.patrolChart.url;
      this.workPermitUrl = selectConfig.main.workPermitStatus.url;
      this.imprProgressUrl = selectConfig.main.imprProgress.url;
      this.workProgressUrl = selectConfig.main.workProgress.url;

      // 년도 설정
      let years = [];
      for (let i = 0; i < 5; i++) {
        years.splice(0, 0, this.$comm.getPrevYear(i));
      }
      this.yearOcuurChart.chartOptions.xaxis.categories = years;

      this.accident.startYmd = years[0];
      this.accident.endYmd = years[4];

      this.getYearList();
      this.getImprStatus(null);
      this.getSafPatrols();
      this.getWorkPermits();
      this.getImprProgress();
      this.getWorkProgress();

      // this.intervalTooltip1();
    },
    getWorkProgress() {
      this.$http.url = this.workProgressUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd1
      }
      this.$http.request((_result) => {
        this.workProgressList = _result.data
      },);
    },
    getWorkPermits() {
      this.$http.url = this.workPermitUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd2,
      };
      this.$http.request((_result) => {
        this.grid1.data = _result.data
      },);
    },
    getImprProgress() {
      this.$http.url = this.imprProgressUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd3,
      };
      this.$http.request((_result) => {
        this.grid2.data = _result.data
      },);
    },
    intervalTooltip1() {
      this.isToolTip1 = setInterval(() => {
        if (this.showTooltopCnt == 8) {
          this.showTooltopCnt = 0;
        }
        for(let c = 0; c < 8; c++) {
          this.showTooltop[c] = false;
        }
        this.showTooltop[this.showTooltopCnt] = true;
        this.showTooltopCnt++;
      }, 2000);
    },
    getSafPatrols() {
      this.$http.url = this.patrolUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ibmTaskTypeCd: 'ITT0000065',
      },
      this.safRate = [];
      this.$http.request((_result) => {
        let taskIdx = 0;
        this.$_.forEach(_result.data, task => {
          this.$_.forEachRight(this.imprChart2.series, item => {
            if (!this.safRate[taskIdx] && task[item.id] > 0) {
              this.safRate[taskIdx] = task.requestTotal > 0 ?
                String(this.$_.round((task.complete / task.requestTotal) * 100)) + '%' : '0%';
            }
          })
          taskIdx++;
        })
        this.$_.forEach(this.imprChart2.series, item => {
          item.data = this.$_.map(_result.data, item.id)
        })
        let xaxis = this.$_.map(_result.data, 'plantName');
        let annotations = [];
        this.safRate.forEach((r, idx) => {
          // xaxis[idx] += (r ? '(' + r + ')' : '');
          annotations[idx] = {
            x: xaxis[idx],
            strokeDashArray: 0,
            borderColor: '#EEEEEE',
            borderWidth: 0,
            label: {
              borderColor: '#775DD0',
              orientation: 'horizontal',
              style: {
                color: '#fff',
                fontSize: '12px',
                background: '#775DD0',
              },
              text: r ? r : '',
              // text: r ? '이행률 : ' + r : '',
            }
          }
        })
        xaxis.forEach((x, idx) => {
          if (!annotations[idx]) {
            annotations[idx] = {
              x: x,
              strokeDashArray: 0,
              borderColor: '#EEEEEE',
              borderWidth: 0,
              // borderColor: '#775DD0',
              label: {
                borderColor: '#775DD0',
                orientation: 'horizontal',
                style: {
                  color: '#fff',
                  fontSize: '12px',
                  background: '#775DD0',
                },
                text: '',
              }
            }
          }
        })
        this.imprChart2.chartOptions.xaxis.categories = xaxis;
        this.imprChart2.chartOptions.annotations.xaxis = annotations;
        
        this.imprChart2.chartWidth = '100%';
        this.$refs['imprChart2'].refresh();
      },);
    },
    getYearList() {
      this.$http.url = this.yearUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.yearOcuurChart.series = [];

        this.$_.forEach(_result.data, _item => {
        // 년도에 따른 idx를 구하여 series.data의 위치 통일
          let idx = this.yearOcuurChart.chartOptions.xaxis.categories.indexOf(_item.occurYear)
          if (this.$_.findIndex(this.yearOcuurChart.series, { plantCd: _item.plantCd}) === -1) {
            this.yearOcuurChart.series.push({
              plantCd: _item.plantCd,
              name: _item.plantName,
              data: [0, 0, 0, 0, 0]
            })
          }
          this.yearOcuurChart.series[this.$_.findIndex(this.yearOcuurChart.series, 
          { plantCd: _item.plantCd })].data[idx] = _item.yearCount
        })
        this.setChart();
      },);
    },
    setChart() {
      setTimeout(() => {
        this.yearOcuurChart.chartWidth = '90%';
      }, 200);
      this.$refs['yearOcuurChart'].refresh();
    },
    datachange4(props) {
      if (props.value === '전체') {
        this.getImprStatus(null);
      } else {
        this.getImprStatus(props.value);
      }
    },
    getImprStatus(_plantCd) {
      this.$http.url = this.rateUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: _plantCd,
        actionCompleteRequestDates: this.actionCompleteRequestDates,
      }
      this.rate = [];
      this.$http.request((_result) => {
        let taskIdx = 0;
        this.$_.forEach(_result.data, task => {
          this.$_.forEachRight(this.imprChart.series, item => {
            if (!this.rate[taskIdx] && task[item.id] > 0) {
              this.rate[taskIdx] = task.requestTotal > 0 ?
                String(this.$_.round((task.complete / task.requestTotal) * 100)) + '%' : '0%';
            }
          })
          taskIdx++;
        })
        this.$_.forEach(this.imprChart.series, item => {
          item.data = this.$_.map(_result.data, item.id)
        })
        let xaxis = this.$_.map(_result.data, 'ibmTaskTypeName');
        let annotations = [];
        this.rate.forEach((r, idx) => {
          xaxis[idx] += (r ? '(' + r + ')' : '');
          annotations[idx] = {
            x: xaxis[idx],
            strokeDashArray: 0,
            borderColor: '#EEEEEE',
            borderWidth: 0,
            label: {
              borderColor: '#775DD0',
              orientation: 'horizontal',
              style: {
                color: '#fff',
                fontSize: '12px',
                background: '#775DD0',
              },
              text: r ? r : '',
              // text: r ? '이행률 : ' + r : '',
            }
          }
        })
        xaxis.forEach((x, idx) => {
          if (!annotations[idx]) {
            annotations[idx] = {
              x: x,
              strokeDashArray: 0,
              borderColor: '#EEEEEE',
              borderWidth: 0,
              // borderColor: '#775DD0',
              label: {
                borderColor: '#775DD0',
                orientation: 'horizontal',
                style: {
                  color: '#fff',
                  fontSize: '12px',
                  background: '#775DD0',
                },
                text: '',
              }
            }
          }
        })
        this.imprChart.chartOptions.xaxis.categories = xaxis;
        this.imprChart.chartOptions.annotations.xaxis = annotations;
        
        this.imprChart.chartWidth = '100%';
        this.$refs['imprChart'].refresh();
      },);
    },
  }
};
</script>
<style lang="sass">
  .cardcontents
    margin-top: 6px
    margin-bottom: 6px
    margin-right: 6px
    .card-body
      padding: 10px !important
    .card-header:before
      font-family: "Material Icons"
      content: "\e876"
    .card-header
      height: 40px
      color: #1ab394
      background: #f8f8f8
      display: inline-block
      padding-top: 8px
      .card-more
        float: right !important
        cursor: pointer
        color: #d2d2d2
      .card-more:hover
        color: #1ab394
.main-header-input
  margin-top: -5px
  padding-right: 5px
  .customDate
    padding-bottom: 0 !important
    .q-field__inner
      padding: 0 !important
.cardcontents .card-body.nopadgrid
  padding-top: 0px !important
.btn-fixed-width-height
  width: 95%
  margin: 3px !important
  pointer-events: none

</style>